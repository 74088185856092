/* TickerComponent.css */
.ticker-container {
    width: 100%;
    overflow: hidden;
    /* background-color: #f5f5f5; */
    padding: 10px 0; /* Adjust padding as needed */
  }
  
  .ticker {
    white-space: nowrap;
    height: 50px;
    animation: scroll 10s linear infinite;
    font-size: 18px; /* Adjust font size as needed */
    color: #fefcfc; /* Text color */
  }
  
  .ticker a {
    font-size: xx-large;
    font-weight: 400;
    display: inline-block;
    margin-right: 50px;
    color: #fefcfc; /* Link color */
    text-decoration: none;
    transition: color 0.5s ease-in-out; /* Smooth color transition */
  }
  
  .ticker a:hover {
    color: #FF5733; /* Hovered link color */
  }
  
  @keyframes scroll {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  /* footer.css */
.custom-mdi {
    font-size: 40px; /* Adjust the font size as needed */
    margin-right: 5px; /* Optional: Add margin for spacing */
    /* Other styling properties, if needed */
  }

  .footer{
    background-color: #FF7953;
  }