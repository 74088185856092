  /* 13/12/24 */
  .custom-card {
    width: 240px;
    height: 380px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    transition: transform 0.5s, background-position 0.5s;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 20px;
    border-radius: 25px;
    box-shadow: 0 4px 8px #ADACAC;
    color: white;
    overflow: hidden;
    margin: 10px;
  }
  
  .card-media {
    height: 100%;
    transition: height 0.5s;
    display: block;
    margin: 0 auto;
    object-fit: cover;
    cursor: pointer;
    padding-bottom: 70px;
  }
  
  .swiper-slide img {
    display: block;
    margin: auto;
  }
  
  .custom-card.hover .card-media {
    height: 60%;
    padding-bottom: 1px;
  }
  
  .card-content {
    padding: 20px;
    padding-top: 20px; 
    position: absolute;
    bottom: -75px;
    width: calc(100% - 40px);
    transition: bottom 0.5s;
  }
  
  .card-title {
    color: #434343;
    font-family: Arial;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: left;
  }
  
  .card-description {
    color: #686868;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    width: 100%;
    text-align: left;
    max-height: 72px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  
  .action-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 16px;
  }
  
  .main-card-price {
    /* color: #FD7E4B; */
    color: #2A2A2A;
    font-family: Arial;
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
  }
  
  .quantity-controls {
    /* display: flex;
    align-items: center;
    background-color: white;
    border: 1px solid gray;
    border-radius: 5px;
    padding: 1px 2px;  */
    transform: scale(0.7);
  }
  
  .quantity-input {
    width: 25px; 
    text-align: center;
    border: none;
    outline: none;
    font-size: 0.75rem; 
  }
  
  .MuiIconButton-root {
    padding: 1px; 
  }
  
  .add-to-cart-btn {
    background-color: #FD7E4B;
    border: none;
    padding: 5px;
    color: white;
    cursor: pointer;
    border-radius: 5px;
    font-weight: bold;
    position: absolute;
    bottom: -80px; 
    transition: bottom 0.5s;
  }
  
  .add-to-cart-btn.show {
    bottom: 20px;
    background-color: #FD7E4B;
    border: none;
    padding: 5px 25px;
    color: white;
    cursor: pointer;
    border-radius: 25px;
    font-weight: bold;
  }
  
  .custom-card:hover .card-content {
    bottom: -35px;
  }
  
  .custom-card:hover .add-to-cart-btn {
    bottom: 20px;
  }
  
  .add-to-cart-btn:hover {
    background-color: red;
  }
  
  .custom-card:hover {
    transform: translateY(-10px);
    background-position: left;
  }
