.wrapper1 {
    /* position: fixed; */
    /* top: 40%; */
    /* left: 50%; */
    /* right: 5%; */
    transform: translate(-50%, -50%);
    margin-right: 100px;
  }
  
.listSocial {
    position: relative;
    /* width: 330px; */
    height: 50px;
    top: 50%;
    
    /* right:50% */
  }
  
#listsocialicons {
    display: inline-block;
    /* margin: 0 15px; */
    position: absolute;
    left: 0;
    top: 0;
    transition: all 0.5s;
  }
/*   
  a {
    display: block;
    width: 50px;
    height: 50px;
    line-height: 50px;
    color: #fff;
    font-size: 18px;
    text-align: center;
  } */
  
#listsocialicons:nth-child(1) {
    z-index: 1;
    background:rgb(6, 44, 119);
  }
  
  #listsocialicons.active:nth-child(2) {
    left: 50px;
    /* background: #3b5998; */
    transition-delay: 200ms;
  }
  
 #listsocialicons.active:nth-child(3) {
    left: 100px;
    /* background: #55acee; */
    transition-delay: 400ms;
  }
  
#listsocialicons.active:nth-child(4) {
    left: 150px;
    /* background: #e95950; */
    transition-delay: 600ms;
  }
  
#listsocialicons.active:nth-child(5) {
    left: 200px;
    /* background: white; */
    transition-delay: 800ms;
  }
  
#listsocialicons.active:nth-child(6) {
    left: 250px;
    background: #dd4b39;
    transition-delay: 1000ms;
  }
  