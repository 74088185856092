.login-container{
    width: 60%;
    height: 100vh;
   display: flex;
   flex-direction: column;
   justify-content: center;
   margin-left: auto;
   margin-right: auto;
 
}

 
.formbgnew{
    background-color:rgba(212, 188, 148, 0.5);
    padding: 20px;
    font-weight: bold;
    border-radius: 10px;
    box-shadow: 2px 2px 2px 2px black;;
    margin: 10px;
}


.admin-container{
    width: 60%;
   margin: 30px;
   display: flex;
   flex-direction: column;
   justify-content: center;
   margin-left: auto;
   margin-right: auto;
 
}
 
.admin-dashboard{
    margin: 30px;
    background-color:rgba(212, 188, 148, 0.5);
    padding: 20px;
    font-weight: bold;
    border-radius: 10px;
    box-shadow: 2px 2px 2px 2px black;;
}
 
.menuitemstyle{
    display: block;
    border-radius: 10px;
    padding: 5px;
    width: 100%;
    border:1px solid white;
    background-color: rgba(212, 188, 148, 0.5);
}





/* Media Query for Mobile Devices */

@media (max-width: 480px) {

    .login-container{
        width: 100%;
        height: 100vh;
       display: flex;
       flex-direction: column;
       justify-content: center;
       margin-left: auto;
       margin-right: auto;
     
    }
  
    .admin-container{
        width: 100%;
       margin: 30px;
       display: flex;
       flex-direction: column;
       justify-content: center;
       margin-left: auto;
       margin-right: auto;
     
    }
}

 

/* Media Query for low resolution  Tablets, Ipads */

@media (min-width: 481px) and (max-width: 767px) {

    .login-container{
        width: 90%;
        height: 100vh;
       display: flex;
       flex-direction: column;
       justify-content: center;
       margin-left: auto;
       margin-right: auto;
     
    }
    .admin-container{
        width: 90%;
       margin: 30px;
       display: flex;
       flex-direction: column;
       justify-content: center;
       margin-left: auto;
       margin-right: auto;
     
    }

}



@keyframes zoomInOut {
    0% { transform: scale(1); }
    50% { transform: scale(1.9); }
    100% { transform: scale(1); }
  }
  
  .zoom-effect {
    animation: zoomInOut 2s infinite; /* Adjust animation duration as needed */
    cursor: pointer;
    border:"2px red solid";
    /* box-shadow: 0 4px 8px white; */
  }

  .highlightedButton {
    border: 50px solid orange;
    background-color: white;
  }
  
